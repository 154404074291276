/**
 * @ComponentFor PodPlayerBlock
 */

import React from 'react';
import { Breakpoint } from '@avensia/scope';
import { epiPropertyValue } from '@avensia/scope-episerver';
import { styled, StyledProps } from '@glitz/react';
import PodPlayerType from './PodPlayerBlock.type';
import { Appearance as ButtonAppearance } from 'Shared/Button';
import P from 'Shared/Generic/P';
import Button from 'Shared/Button';
import { pixelsToUnit, large, minMediumMediaQuery, huge } from 'Shared/Style';
import connect from 'Shared/connect';
import { BOX_ITEM_CLASSNAME } from 'Shared/BoxLayout';
import { toggleRadioPlay } from 'RadioPlay/action-creators';

type ConnectStateType = {
  currentBreakpoint: number;
};

type ConnectActionType = {
  onToggleRadioPlay: (isOpen: boolean, embedRadioPlayUrl: string) => void;
};

type PropType = PodPlayerType & ConnectActionType & ConnectStateType & StyledProps;

const podPlayerBlock = (props: PropType) => {
  const isMobile = props.currentBreakpoint < Breakpoint.Medium;
  return (
    <Wrapper css={props.compose()} className={BOX_ITEM_CLASSNAME}>
      <h2>{epiPropertyValue(props.title)}</h2>
      <Description>{epiPropertyValue(props.description)}</Description>
      {isMobile ? (
        <CustomButton to={epiPropertyValue(props.embedUrl).url} target="_blank" appearance={ButtonAppearance.Secondary}>
          {epiPropertyValue(props.button)}
        </CustomButton>
      ) : (
        <CustomButton
          // tslint:disable-next-line:jsx-no-lambda
          onClick={() => props.onToggleRadioPlay(true, epiPropertyValue(props.embedUrl).url)}
          appearance={ButtonAppearance.Secondary}
        >
          {epiPropertyValue(props.button)}
        </CustomButton>
      )}
    </Wrapper>
  );
};

export default styled(
  connect(
    (state): ConnectStateType => ({
      currentBreakpoint: state.currentBreakpoint,
    }),
    (dispatch): ConnectActionType => ({
      onToggleRadioPlay(isOpen, embedRadioPlayUrl) {
        dispatch(toggleRadioPlay(isOpen, embedRadioPlayUrl));
      },
    }),
  )(podPlayerBlock),
);

const Wrapper = styled.div({
  padding: { y: large },
  [minMediumMediaQuery]: {
    padding: { y: huge },
  },
});

const Description = styled(P, {
  fontWeight: 'bold',
  padding: {
    y: large,
  },
});

const CustomButton = styled(Button, {
  letterSpacing: pixelsToUnit(0.8),
  padding: {
    y: 0,
    x: pixelsToUnit(30),
  },
});
